<template>
  <div class="all">
    <iframe
      src="http://test.dscbs.com/pages/bookManage/form/index?obj.id=40288131549dde9001549e859a8e0046"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>

.all {
  display: flex;
  // align-items: center;
  justify-content: center;
   margin-top: calc(-140px + .7rem);
  height: 1250px;
  // width: 100%;
  position: relative;
  // overflow: hidden;
  // margin-bottom: -3rem;
  background: #fff;
  iframe{
    height: 1500px;
    width: 1200px;
    
    
    //  margin-bottom:-325px
  }
 
}
 
 @media (max-width: 1000px){
  .all{
     margin-bottom: 0rem;
       height: 1150px;
       overflow: hidden;
  }
   
 }
@media (max-width: 768px) {
  .all {
    
  }
   
}
</style>